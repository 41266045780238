// Enter all your detials in this file
// Logo images
import logogradient from "./assets/logo.svg";
import logo from "./assets/logo2.svg";
// Profile Image
import profile from "./assets/profile.png";
// Tech stack images
import html from "./assets/techstack/html.png";
import css from "./assets/techstack/css.png";
import js from "./assets/techstack/js.png";
import react from "./assets/techstack/react.png";
import tailwind from "./assets/techstack/tailwind.png";
import bootstrap from "./assets/techstack/bootstrap.png";
import vscode from "./assets/techstack/vscode.png";
import github from "./assets/techstack/github.png";
import git from "./assets/techstack/git.png";
import npm from "./assets/techstack/npm.png";
import figma from "./assets/techstack/figma.png";
// Porject Images
import projectImage1 from "./assets/projects/project1.jpg";
import projectImage2 from "./assets/projects/project2.jpg";
import projectImage3 from "./assets/projects/project3.jpg";
import projectImage4 from "./assets/projects/project4.jpg";
import projectImage5 from "./assets/projects/project5.jpg";
import projectImage6 from "./assets/projects/project6.jpg";

// Logos
export const logos = {
  logogradient: logogradient,
  logo: logo,
};

// Enter your Personal Details here
export const personalDetails = {
  name: "M ARIF USMAN",
  tagline: "I build stuff for website",
  img: profile,
  about: `Saya seorang IT Enthusiast, saya telah banyak membuat website untuk UMKM, Sekolah, maupun Lembaga`,
};

// Enter your Social Media URLs here
export const socialMediaUrl = {
  linkdein: "https://www.linkedin.com/moeharif",
  github: "https://www.github.com/moeharif",
  twitter: "https://twitter.com/@iiNoct_",
  instagram: "https://www.instagram.com/@moeharif",
};

// Enter your Work Experience here
export const workDetails = [
  {
    Position: "CEO",
    Company: `Noct Digi`,
    Location: "Makassar",
    Type: "Full Time",
    Duration: "Maret 2021 - Sekarang",
  },
  {
    Position: "Digital Agent",
    Company: `Telkom Akses`,
    Location: "Makassar",
    Type: "Full Time",
    Duration: "Desember 2020 - Maret 2023",
  },
  {
    Position: "Quality Control (QC)",
    Company: `Pattalassang Land Farm`,
    Location: "Gowa",
    Type: "Full Time",
    Duration: "November 2017 - Desember 2020",
  },
];

// Enter your Education Details here
export const eduDetails = [
  {
    Position: "Entry Bootcamp",
    Company: "Hacktiv8",
    Location: "JogjaCamp",
    Type: "Full Time",
    Duration: "Maret 2020",
  },
  {
    Position: "Sarjana Fisika",
    Company: `UIN Alauddin Makassar`,
    Location: "Makassar",
    Type: "Full Time",
    Duration: "September 2012 - Juli 2019",
  },
];

// Tech Stack and Tools
export const techStackDetails = {
  html: html,
  css: css,
  js: js,
  react: react,
  tailwind: tailwind,
  bootstrap: bootstrap,
  vscode: vscode,
  npm: npm,
  git: git,
  github: github,
  figma: figma,
};

// Enter your Project Details here
export const projectDetails = [
  {
    title: "MA Darul Ulum Maros",
    image: projectImage1,
    description: `This is sample project description random things are here in description This is sample
    project lorem ipsum generator for dummy content`,
    techstack: "Wordpress, Laravel",
  },
  {
    title: "HMJ Fisika UINAM",
    image: projectImage2,
    description: `This is sample project description random things are here in description This is sample
project lorem ipsum generator for dummy content`,
    techstack: "Wordpress, HTML/CSS",
  },
  {
    title: "Chordpass",
    image: projectImage3,
    description: `This is sample project description random things are here in description This is sample
project lorem ipsum generator for dummy content`,
    techstack: "HTML/CSS, JavaScript",
  },
  {
    title: "Cateringbox Putri",
    image: projectImage4,
    description: `This is sample project description random things are here in description This is sample
project lorem ipsum generator for dummy content`,
    techstack: "Wordpress, Elementor",
  },
  {
    title: "Makassar Pet Clinic",
    image: projectImage5,
    description: `This is sample project description random things are here in description This is sample
project lorem ipsum generator for dummy content`,
    techstack: "HTML/CSS, JavaScript",
  },
  {
    title: "My Personal Blog",
    image: projectImage6,
    description: `This is sample project description random things are here in description This is sample
project lorem ipsum generator for dummy content`,
    techstack: "HTML/CSS, JavaScript",
  },
];

// Enter your Contact Details here
export const contactDetails = {
  email: "moeh.arif.usman@gmail.com",
  phone: "+62 831 3177 4826",
};
